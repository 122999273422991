<template>
  <div class="page">
    <div class="container">
      <div class="header">
        <el-image :src="require('@/assets/LOGO.svg')" @click="toHome" style="cursor: pointer;width: 155px; height: 21px;"></el-image>
        <div class="header__content">
          <a href="mailto:exam@holowits.com" class="contact">Contact Us</a>
        </div>
      </div>
      <div class="container__body">
        <h1 class="login_title">{{$i18n.t('login.title')}}</h1>
        <el-form :model="loginForm" class="login_form" :rules="rules" ref="loginForm">
          <el-form-item prop="account">
            <el-input :placeholder="$i18n.t('login.email')" v-model="loginForm.account"/>
          </el-form-item>
          <el-form-item prop="password">
            <el-input class="password-input" :placeholder="$i18n.t('login.pwd')" v-model="loginForm.password" show-password/>
          </el-form-item>
        </el-form>
        <el-button type="primary" class="submit_btn" :loading="submitLoading" @click="submitLogin">{{$i18n.t('common.login')}}</el-button>
        <div style="margin-top: 20px; display: flex; justify-content: space-between;">
          <div class="register" @click="goRegister">{{$i18n.t('login.register')}}</div>
          <!-- <div class="link" @click="goChangePassword">{{$i18n.t('login.changePwd')}}</div>
            <div class="link" @click="goForgetPassword">{{$i18n.t('login.forgetPwd')}}</div> -->
          <div class="modify_pwd">
            <div class="link" @click="goChangePassword">{{$i18n.t('login.changePwd')}}</div>
            <div class="link" @click="goForgetPassword">{{$i18n.t('login.forgetPwd')}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {login} from '@/api/login';
  import {Message} from 'element-ui';
  import db from '@/utils/localstorage'
  export default {
    name: 'index',
    data() {
      return {
        loading: false,
        submitLoading: false,
        loginForm: {
          account: '',
          password: '',
          tenant: 'cGFydG5lcg==',
          grantType: 'password',
        },
        rules: {
          account: [{required: true, message: 'Email cannot be empty.', trigger: 'blur'}],
          password: [{required: true, message: 'Password cannot be empty.', trigger: 'blur'}],
        },
        prevPagePath: '/',
        redirect: ''
      };
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.prevPagePath = from.path;
      });
    },
    created() {
      // console.log(this.$i18n)
      if (Object.keys(this.$route.query).length > 0) {
        if (this.$route.query.redirect) {
          // 利用重定向，登录成功后跳转到其它网址。故而官网的登录可以做成类似平台的效果
          this.redirect = this.$route.query.redirect;
        }
      }
    },
    methods: {
      goChangePassword() {
        this.$router.push({
          path: '/changePassword',
        });
      },
      goForgetPassword() {
        this.$router.push({
          path: '/forgetPassword',
        });
      },

      goRegister() {
        this.$router.push({
          path: '/register'
        })
      },

      toHome() {
        this.$router.replace({name: 'home'});
      },

      submitLogin() {
        this.$refs.loginForm.validate(valid => {
          if (valid) {
            this.loading = true;
            this.$store.commit('client/setTenant', this.loginForm.tenant);
            const meta = {
              tenant: this.loginForm.tenant,
              'X-isTenant': false,
              'X-isToken': false,
            };
            login(this.loginForm, meta).then(res => {
              const response = res.data;
              if (response.isSuccess) {
                this.saveLoginData(response.data['token'], response.data['refreshToken'], response.data['expiration']);
                // 如果登录路径有重定向参数，这意味着是要跳转到holowits其它网站
                if (this.redirect) {
                  db.setCookie('CLIENT_TENANT', this.loginForm.tenant, 30, '/')
                  db.setCookie('CLIENT_TOKEN', response.data['token'], 30, '/')
                  db.setCookie('CLIENT_REFRESH_TOKEN', response.data['refreshToken'], 30, '/')
                  db.setCookie('CLIENT_EXPIRE_TIME', response.data['expiration'], 30, '/')
                  db.setCookie('CLIENT_USER', JSON.stringify({
                    id: response.data.userId,
                    account: response.data.account,
                    name: response.data.name,
                    avatar: response.data.avatar,
                    workDescribe: response.data.workDescribe,
                  }),
                  30, '/')
                  window.location.href = this.redirect;
                  return false;
                }
                // 测试用----------------------
                // db.setCookie('CLIENT_TENANT', this.loginForm.tenant, 30, '/')
                // db.setCookie('CLIENT_TOKEN', response.data['token'], 30, '/')
                // db.setCookie('CLIENT_REFRESH_TOKEN', response.data['refreshToken'], 30, '/')
                // db.setCookie('CLIENT_EXPIRE_TIME', response.data['expiration'], 30, '/')
                // db.setCookie('CLIENT_USER', JSON.stringify({
                //   id: response.data.userId,
                //   account: response.data.account,
                //   name: response.data.name,
                //   avatar: response.data.avatar,
                //   workDescribe: response.data.workDescribe,
                // }),30, '/')
                // 测试用---------------------
                this.saveUserInfo(response.data);
                this.loginSuccess()
              }
            });
          } else {
            return false;
          }
        });
      },
      saveLoginData(token, refreshToken, expiration) {
        this.$store.commit('client/setToken', token);
        this.$store.commit('client/setRefreshToken', refreshToken);
        this.$store.commit('client/setExpireTime', expiration);
      },
      saveUserInfo(user) {
        this.$store.commit('client/setUser', {
          id: user.userId,
          account: user.account,
          name: user.name,
          avatar: user.avatar,
          workDescribe: user.workDescribe,
        });
      },
      loginSuccess() {
        this.$message({
          message: this.$t('tipInfo.loginSuccess'),
          type: 'success',
        });
        console.log("login页面之前的路由：",this.prevPagePath)
        if(this.prevPagePath=='/changePassword' || this.prevPagePath=='/forgetPassword' || this.prevPagePath== '/resetPassword'){
          this.$router.push('/');
          return
        }
        this.$router.push({path: this.prevPagePath});
      },
    },
  };
</script>

<style scoped lang="less">
  @import "login";
</style>
<style scoped lang="scss">
  .cus-popper {
    transform: translateY(-8px);
  }
  .register {
    font-size: 14px;
    font-weight: 400;
    color: #30B5C5;
    line-height: 16px;
    cursor: pointer;
  }
</style>
